import {
  useState,
  useEffect,
  createContext,
  useContext,
  Dispatch,
  SetStateAction,
} from "react";
import {
  User,
  Progress,
  ProgressStatusKind,
  ProgressStatusWithPayload,
  TTUser,
  Tag,
} from "../model";

import api from "../api";
import TinyLoadingSpinner from "../components/spinners/TinyLoadingSpinner";
import TiktokLoadingSpinner from "../components/spinners/TiktokLoadingSpinner";
import ProgressBar from "../components/ProgressBar";
import SettingsSidePanel from "../components/SettingsSidePanel";
import {
  SortAscendIcon,
  SortDescendIcon,
  ClipboardIcon,
  ClipboardWithCheckmarkIcon,
} from "../components/Icons";

import { Tooltip } from "react-tooltip";
import { clsx } from "clsx";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";
import MultiselectMenu from "../components/scope/MultiselectMenu";

interface FindLookalikesViewProps {
  socket: any;
  user: User;
  tags: Tag[];
  tokenExpired: boolean;
  setShouldReceiveEmails: (isEnabled: boolean) => void;
}

const DataContext = createContext<{
  selectedUsers: number[];
  user?: User;
  isSidePanelOpen: boolean;
  setIsSidePanelOpen: Dispatch<SetStateAction<boolean>>;
  tokenExpired: boolean;
}>({
  selectedUsers: [],
  isSidePanelOpen: false,
  tokenExpired: false,
  setIsSidePanelOpen: function (_: SetStateAction<boolean>): void {
    throw new Error("Function not implemented.");
  },
});

export const useDataContext = () => useContext(DataContext);

export function FindLookalikesView({
  socket,
  user,
  setShouldReceiveEmails,
  tokenExpired,
}: FindLookalikesViewProps) {
  const [inProgress, setInProgress] = useState(false);
  const [progressError, setProgressError] = useState(false);
  const [progressMsg, setProgressMsg] = useState("");
  const [currentProgressPercentage, setCurrentProgressPercentage] =
    useState(0.0);
  const [requestedUser, setRequestedUser] = useState<TTUser | null>(null);
  const [users, setUsers] = useState<TTUser[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<number[]>([]);
  let isFetching = false;
  useEffect(() => {
    let query = new URLSearchParams(window.location.search);
    let userInQuery = query.get("username");
    if (userInQuery !== null && !isFetching) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      isFetching = true;
      setTimeout(() => {
        startFindLookalikes(userInQuery as string);
      }, 200);
    }
  }, [socket]);

  const subscribeToJobUpdates = (jobId: string) => {
    const jobAddr = `jobProgress/${jobId}`;
    console.log("sub to", jobAddr, socket);
    socket.on(jobAddr, (progress: Progress) => {
      console.log(progress);
      setProgressMsg(progress.msg);
      setCurrentProgressPercentage(progress.progress * 100.0);
      if (progress.status.type === ProgressStatusKind.Error) {
        setInProgress(false);
        setProgressError(true);
        socket.off(jobAddr);
        console.log("unsub from", jobAddr);
      } else if (progress.status.type === ProgressStatusKind.Done) {
        let status = progress.status as ProgressStatusWithPayload;
        setInProgress(false);
        setProgressMsg("");
        setRequestedUser(status.payload.requestedUser);

        let users: TTUser[] = status.payload.users.map((u) => {
          let engagement = status.payload.usersEngagement.find(
            (e) => e.ttUserId === u.id,
          );
          return { ...u, engagement: engagement } as TTUser;
        });
        setUsers(users);
        socket.off(jobAddr);
        console.log("unsub from", jobAddr);
      }
    });
  };

  const startFindLookalikes = (username: string) => {
    setUsers([]);
    setRequestedUser(null);
    setCurrentProgressPercentage(0.0);
    setProgressError(false);
    setProgressMsg("");
    setInProgress(true);
    api
      .startLookalikeJob(username)
      .then((resp) => {
        const jobId = resp.data;
        subscribeToJobUpdates(jobId);
        window.history.pushState("", "", `?username=${username}`);
      })
      .catch((e) => {
        setInProgress(false);
        setProgressError(true);
        setProgressMsg(
          "There was an issue when trying to run lookalike:" + e.toString(),
        );
      });
  };

  const onUserSelection = (userId: number, selectHidden: boolean = false) => {
    if (userId === -2) {
      // force empty
      setSelectedUsers([]);
    } else if (userId === -1) {
      // toggle all/none
      const userLength = selectHidden
        ? users.length
        : requestedUser
          ? users.filter((u) => filterUser(requestedUser, u)).length
          : 0;
      if (selectedUsers.length !== userLength) {
        if (selectHidden) {
          setSelectedUsers(users.map((u) => u.id));
        } else if (requestedUser) {
          let nonHiddenUsers = users.filter((u) =>
            filterUser(requestedUser, u),
          );
          setSelectedUsers(nonHiddenUsers.map((u) => u.id));
        }
      } else {
        setSelectedUsers([]);
      }
    } else if (selectedUsers.includes(userId)) {
      setSelectedUsers(selectedUsers.filter((uid) => uid !== userId));
    } else {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const [isSidePanelOpen, setIsSidePanelOpen] = useState(false);

  return (
    <DataContext.Provider
      value={{
        selectedUsers,
        user,
        setIsSidePanelOpen,
        isSidePanelOpen,
        tokenExpired,
      }}
    >
      <FindLookalikes
        startFindLookalikes={startFindLookalikes}
        setShouldReceiveEmails={setShouldReceiveEmails}
        isInProgress={inProgress}
        isError={progressError}
        isEmailNotificationsEnabled={user.shouldReceiveEmails}
        currentMsg={progressMsg}
        currentProgressPercentage={currentProgressPercentage}
        user={user}
        requestedUser={requestedUser}
        users={users}
        selectedUsers={selectedUsers}
        onUserSelection={onUserSelection}
      />
      {
        // <TagsPopover
        //   tags={tags}
        //   selectedUsers={selectedUsers}
        //   resetSelectedUsers={() => {
        //     setSelectedUsers([]);
        //   }}
        // />
      }
    </DataContext.Provider>
  );
}

interface FindlookalikesProps {
  startFindLookalikes: (username: string) => void;
  setShouldReceiveEmails: (isEnabled: boolean) => void;
  isInProgress: boolean;
  isError: boolean;
  isEmailNotificationsEnabled: boolean;
  currentMsg: string;
  currentProgressPercentage: number;
  user: User;
  requestedUser: TTUser | null;
  users: TTUser[];
  selectedUsers: number[];
  onUserSelection: (userId: number, selectHidden?: boolean) => void;
}
function FindLookalikes(props: FindlookalikesProps) {
  const {
    startFindLookalikes,
    setShouldReceiveEmails,
    isInProgress,
    isError,
    isEmailNotificationsEnabled,
    currentMsg,
    currentProgressPercentage,
    user,
    requestedUser,
    users,
    selectedUsers,
    onUserSelection,
  } = props;

  return (
    <div className={"h-full"}>
      <div
        className={
          "bg-white px-8 py-8 lg:px-32 lg:py-12 lg:flex lg:justify-center shadow-sm w-full"
        }
      >
        <div className={"lg:w-3/4 flex"}>
          <UserInputBar
            inProgress={isInProgress}
            isEmailNotificationsEnabled={isEmailNotificationsEnabled}
            findLookalike={startFindLookalikes}
            setShouldReceiveEmails={setShouldReceiveEmails}
          />
        </div>
        <SettingsSidePanel
          user={user}
          setShouldReceiveEmails={setShouldReceiveEmails}
        />
      </div>
      <div className={"ml-4 mr-4 lg:ml-32 lg:mr-32"}>
        <LookalikesPanel
          inProgress={isInProgress}
          currentProgressPercentage={currentProgressPercentage}
          error={isError}
          msg={currentMsg}
          requestedUser={requestedUser}
          users={users}
          selectedUsers={selectedUsers}
          onUserSelection={onUserSelection}
          isEmailNotificationsEnabled={isEmailNotificationsEnabled}
        />
      </div>
    </div>
  );
}

interface UserInputBarProps {
  inProgress: boolean;
  isEmailNotificationsEnabled: boolean;
  findLookalike: (username: string) => void;
  setShouldReceiveEmails: (isEnabled: boolean) => void;
}
function UserInputBar(props: UserInputBarProps) {
  const { inProgress, findLookalike } = props;
  const [usernameInput, setUsernameInput] = useState("");
  const onFindLookalike = () => {
    findLookalike(usernameInput);
  };

  useEffect(() => {
    if (typeof window !== "undefined") {
      const s = window.location.search?.split("=")?.[1];
      setUsernameInput(s || "");
    }
  }, []);
  return (
    <div
      className={
        "w-full lg:flex text-center justify-center rounded-lg border-gray-200"
      }
    >
      <input
        className={
          "w-full lg:w-2/3 py-2 px-4 mr-4 bg-white border-0 rounded-full shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:border-indigo-500"
        }
        placeholder="TikTok Username"
        value={usernameInput}
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        onChange={(e) => setUsernameInput(e.target.value.trim())}
        onKeyDown={(e) => {
          if (e.key === "Enter" && usernameInput.length) {
            onFindLookalike();
          }
        }}
      />
      <div
        className={
          "w-full lg:w-1/3 flex justify-center items-center lg:justify-normal mt-4 lg:mt-0"
        }
      >
        <button
          className={clsx(
            "w-1/2 lg:w-3/4 bg-indigo-600 py-2 px-4 flex items-center justify-center space-x-2 text-white font-bold rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2",
            inProgress && "cursor-not-allowed opacity-50",
          )}
          disabled={inProgress}
          onClick={onFindLookalike}
        >
          Get Lookalikes
          {inProgress && <TinyLoadingSpinner />}
        </button>
      </div>
    </div>
  );
}

interface LookalikesPanelProps {
  inProgress: boolean;
  error: boolean;
  currentProgressPercentage: number;
  msg: string;
  requestedUser: TTUser | null;
  users: TTUser[];
  selectedUsers: number[];
  onUserSelection: (userId: number, selectHidden?: boolean) => void;
  isEmailNotificationsEnabled: boolean;
}
function LookalikesPanel({
  inProgress,
  error,
  currentProgressPercentage,
  msg,
  requestedUser,
  users,
  selectedUsers,
  onUserSelection,
  isEmailNotificationsEnabled
}: LookalikesPanelProps) {
  return (
    <div className={"p-8 mt-8 bg-white rounded-lg shadow-sm"}>
      {!inProgress && !error && users.length === 0 && (
        <h2 className={"text-lg font-medium text-center"}>
          Enter a TikTok username you want to find similar profiles to
        </h2>
      )}
      {error && (
        <div className={"flex justify-center items-center"}>
          <h2 className={"text-lg whitespace-pre-line font-medium"}>{msg}</h2>
          <ExclamationCircleIcon
            className={"w-100 h-12 w-12 ml-20 text-yellow-400"}
          />
        </div>
      )}
      {inProgress && (
        <>
          <TiktokLoadingSpinner />
          <h2 className={"mt-4 mb-4 text-lg font-medium text-center"}>
            This usually takes 2-10 minutes. Ps. You can close this window, the profile will still process in the background.
            {isEmailNotificationsEnabled ?
                " We will send you an email when the results are ready."
                :
                " If you would like to receive an email when the results are ready you can enable it in the settings."
            }
          </h2>
          <ProgressBar progress={currentProgressPercentage} />
          <h2 className={"mt-4 text-lg font-medium text-center"}>{msg}</h2>
        </>
      )}
      {requestedUser && users.length > 0 && (
        <div className={"overflow-auto"}>
          <LookalikesTable
            requestedUser={requestedUser}
            users={users}
            selectedUsers={selectedUsers}
            onUserSelection={onUserSelection}
          />
        </div>
      )}
    </div>
  );
}

function getImageUrl(secUid: string): string {
  return `https://tiktok-img-prod.s3.eu-central-1.amazonaws.com/${secUid}.jpg`;
}

interface LookalikesTableProps {
  requestedUser: TTUser;
  users: TTUser[];
  selectedUsers: number[];
  onUserSelection: (userId: number, selectHidden?: boolean) => void;
}
enum SortByColumn {
  Default,
  Region,
  Followers,
  EngagementRatio,
  MedianViews,
}
enum SortDirection {
  Ascending,
  Descending,
}
type ColumnSorting = {
  column: SortByColumn;
  direction: SortDirection;
};

function filterUserByRegion(requestedUser: TTUser, u: TTUser): boolean {
  return requestedUser.region === u.region;
}

function filterUserByGender(requestedUser: TTUser, u: TTUser): boolean {
  if (requestedUser.gender === null) {
    return true;
  }
  if (u.gender === requestedUser.gender || u.gender === null) {
    return true;
  }
  return false;
}

function filterUser(requestedUser: TTUser, user: TTUser): boolean {
  return (
    filterUserByRegion(requestedUser, user) &&
    filterUserByGender(requestedUser, user)
  );
}

function LookalikesTable({
  requestedUser,
  users,
  selectedUsers,
  onUserSelection,
}: LookalikesTableProps) {
  const { user } = useDataContext();
  const [showHidden, setShowHidden] = useState(false);
  const [currentSort, setCurrentSort] = useState<ColumnSorting>({
    column: SortByColumn.Default,
    direction: SortDirection.Descending,
  });
  const sortByColumn = (by: SortByColumn) => {
    let direction: SortDirection = SortDirection.Descending;
    if (by === currentSort.column) {
      direction =
        currentSort.direction === SortDirection.Ascending
          ? SortDirection.Descending
          : SortDirection.Ascending;
    }
    let newSort: ColumnSorting = {
      column: by,
      direction: direction,
    };
    sortRows(users, newSort);
    setCurrentSort(newSort);
  };

  return (
    <table className={"table-auto w-full"}>
      <thead>
        <tr className={"border-b-2 border-gray-200"}>
          <th className="text-left max-w-8 w-8 whitespace-nowrap">
            <MultiselectMenu
              selectionLength={selectedUsers.length}
              profilesLength={
                showHidden
                  ? users.length
                  : requestedUser
                    ? users.filter((u) => filterUser(requestedUser, u)).length
                    : 0
              }
              updateSelection={(uid = -1) => {
                onUserSelection(uid, showHidden);
              }}
              failOver={!user?.scopeApiKey}
            />
          </th>
          <th />
          <th
            className={
              "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
            }
          >
            Name
          </th>
          <th
            className={
              "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
            }
          >
            Link
          </th>
          <th>
            <SortButton
              classes={
                "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
              }
              label={"Region"}
              onClick={() => sortByColumn(SortByColumn.Region)}
            />
          </th>
          <th>
            <SortButton
              classes={
                "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
              }
              label={"Followers"}
              onClick={() => sortByColumn(SortByColumn.Followers)}
            />
          </th>
          <th>
            <SortButton
              classes={
                "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
              }
              label={"Engagement"}
              onClick={() => sortByColumn(SortByColumn.EngagementRatio)}
            />
          </th>
          <th>
            <SortButton
              classes={
                "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900"
              }
              label={"Views"}
              onClick={() => sortByColumn(SortByColumn.MedianViews)}
            />
          </th>
        </tr>
      </thead>
      <tbody className={"divide-y divide-gray-200 bg-white"}>
        {users
          .filter((u) => showHidden || filterUser(requestedUser, u))
          .map((user) => {
            let isHidden = !filterUser(requestedUser, user);
            return (
              <tr
                key={user.username}
                className={clsx(isHidden && "opacity-50")}
              >
                <td
                  className={
                    "py-2 whitespace-nowrap text-sm w-8 max-w-8 text-gray-500"
                  }
                >
                  <input
                    type={"checkbox"}
                    checked={selectedUsers.includes(user.id)}
                    onClick={() => {
                      onUserSelection(user.id);
                    }}
                  />
                </td>
                <td
                  className={"whitespace-nowrap py-4 pl-6 pr-3 text-sm sm:pl-4"}
                >
                  <div className={"flex items-center"}>
                    <div className={"h-10 w-10 flex-shrink-0"}>
                      <img
                        className={"h-10 w-10 rounded-full"}
                        alt={`@${user.username}`}
                        src={getImageUrl(user.secUid)}
                        onError={(e) => {
                          e.currentTarget.src = getImageUrl("MISSING_AVATAR");
                        }}
                      />
                    </div>
                  </div>
                </td>
                <td
                  className={"whitespace-nowrap py-4 pl-6 pr-3 text-sm sm:pl-0"}
                >
                  <div className={"font-medium"}>
                    {user.nickname}
                    {user.email && (
                      <div className={"flex items-center"}>
                        <div className={"font-medium text-gray-600 mr-2"}>
                          {user.email}
                        </div>
                        <CopyEmailButton email={user.email} />
                      </div>
                    )}
                  </div>
                </td>

                <td
                  className={
                    "px-4 py-2 whitespace-nowrap text-sm font-medium text-indigo-600"
                  }
                >
                  <a
                    href={`https://www.tiktok.com/@${user.username}`}
                    rel={"noreferrer"}
                    target={"_blank"}
                  >
                    {user.username}
                  </a>
                </td>

                <td
                  className={
                    "px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                  }
                >
                  {user.region ? user.region : "--"}
                </td>

                <td
                  className={
                    "px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                  }
                >
                  {abbreviateNumberIfNeeded(user.numFollowers)}
                </td>

                <td
                  className={
                    "px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                  }
                >
                  {user.engagement
                    ? `${user.engagement.engagementRatio
                        .toString()
                        .substring(0, 4)}%`
                    : "--"}
                </td>

                <td
                  className={
                    "px-4 py-2 whitespace-nowrap text-sm text-gray-500"
                  }
                >
                  {user.engagement
                    ? abbreviateNumberIfNeeded(user.engagement.medianNumViews)
                    : "--"}
                </td>
              </tr>
            );
          })}
        <tr>
          <td colSpan={3}>
            <button
              onClick={() => setShowHidden(!showHidden)}
              className={
                "bg-indigo-600 mt-2 py-2 px-6 flex items-center justify-center space-x-2 text-white font-bold rounded-lg hover:bg-indigo-700 focus:outline-none focus:ring-indigo-500 focus:ring-offset-2"
              }
            >
              {!showHidden ? "Show Hidden" : "Show Less"}
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  );
}

function abbreviateNumberIfNeeded(n: number): string {
  if (n < 1000) {
    return n.toString();
  } else if (n >= 1000 && n < 1000000) {
    return (n / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  } else {
    return (n / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
}

function sortRows(users: TTUser[], sorting: ColumnSorting) {
  if (sorting.column === SortByColumn.Region) {
    users.sort((a, b) => {
      if (!a.region) return 1;
      if (!b.region) return -1;
      return sorting.direction === SortDirection.Ascending
        ? a.region > b.region
          ? 1
          : b.region > a.region
            ? -1
            : 0
        : b.region > a.region
          ? 1
          : a.region > b.region
            ? -1
            : 0;
    });
  } else if (sorting.column === SortByColumn.Followers) {
    users.sort((a, b) => {
      return sorting.direction === SortDirection.Ascending
        ? b.numFollowers - a.numFollowers
        : a.numFollowers - b.numFollowers;
    });
  } else if (sorting.column === SortByColumn.EngagementRatio) {
    users.sort((a, b) => {
      if (!a.engagement || !b.engagement) return 0;
      return sorting.direction === SortDirection.Ascending
        ? b.engagement.engagementRatio - a.engagement.engagementRatio
        : a.engagement.engagementRatio - b.engagement.engagementRatio;
    });
  } else {
    users.sort((a, b) => {
      if (!a.engagement || !b.engagement) return 0;
      return sorting.direction === SortDirection.Ascending
        ? b.engagement.medianNumViews - a.engagement.medianNumViews
        : a.engagement.medianNumViews - b.engagement.medianNumViews;
    });
  }
}

interface SortButtonProps {
  classes: string;
  label: string;
  onClick: () => void;
}
function SortButton({ classes, label, onClick }: SortButtonProps) {
  const [isAscending, setIsAscending] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  return (
    <div className={clsx(isHovering && "bg-gray-100", classes)}>
      <button
        onMouseOver={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          setIsActive(true);
          setIsAscending(!isAscending);
          onClick();
        }}
        className={"w-full h-4 text-left flex items-center"}
      >
        {label}
        <div className={"w-4 h-4 ml-4 mt-1 text-blue-600"}>
          {(isHovering || isActive) && (
            <>{isAscending ? <SortAscendIcon /> : <SortDescendIcon />}</>
          )}
        </div>
      </button>
    </div>
  );
}

function CopyEmailButton({ email }: { email: string }) {
  const id = "copy-email-" + email;

  const [isDone, setIsDone] = useState(false);
  const onClick = () => {
    navigator.clipboard.writeText(email).then(() => {
      setIsDone(true);
    });
  };

  const tooltip = isDone ? "Copied!" : "Copy Email to Clipboard";

  return (
    <>
      <Tooltip id={id} content={tooltip} />
      <button
        data-tooltip-id={id}
        onClick={onClick}
        className={clsx(
          "bg-gray-200 text-gray-600 hover:bg-gray-300 p-1 rounded-full",
          isDone && "bg-blue-200 hover:bg-blue-100",
        )}
      >
        {isDone ? <ClipboardWithCheckmarkIcon /> : <ClipboardIcon />}
      </button>
    </>
  );
}
