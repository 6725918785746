export type Option<T> = T | null;

export type User = {
    id: number,
    email: string,
    shouldReceiveEmails: boolean,
    scopeApiKey: string
};

export type Tag = {
    id: number,
    name: string
};

export type JobProgress = Progress | null;
export type Progress = {
    jobId: string,
    status: ProgressStatus,
    progress: number,
    msg: string
};

export enum ProgressStatusKind {
    Pending="Pending",
    InProgress="InProgress",
    Done="Done",
    Error="Error"
};

export type ProgressStatusBase = {
    type: ProgressStatusKind,
};

export type ProgressStatusWithPayload = {
    type: ProgressStatusKind.Done,
    payload: LookalikesJobPayload
};

export type ProgressStatus = ProgressStatusBase | ProgressStatusWithPayload;

export type LookalikesJobPayload = {
    requestedUser: TTUser,
    users: TTUser[],
    usersEngagement: TTUserEngagement[]
};

export type Gender = "Male" | "Female";

export type TTUser = {
    id: number,
    tikdokId: string,
    username: string,
    secUid: string,
    nickname: string,
    isVerified: boolean,
    isPrivate: boolean,
    isSecret: boolean,
    numFollowing: number,
    numFollowers: number,
    numVideos: number,
    numLikes: number,
    numFriends: number,
    gender?: Option<Gender>,
    region?: Option<string>,
    email: Option<string>,
    bioText: Option<string>,
    bioLink: Option<string>,
    engagement: Option<TTUserEngagement>
};

export type TTUserEngagement = {
    ttUserId: number,
    engagementRatio: number,
    medianNumLikes: number,
    medianNumComments: number,
    medianNumViews: number,
    numPosts: number
};
