import { useState, useEffect } from "react";
import "./App.css";
import { io } from "socket.io-client";

import api from "./api";
import { FindLookalikesView } from "./views/FindLookalike";
import { Option, User, Tag } from "./model";
import { Toaster } from "react-hot-toast";

const socket = io(window.location.host /*"https://localhost"*/, {
  transports: ["websocket"],
});
socket.on("connect", () => {
  console.log("socket connected");
});

function App() {
  const [user, setUser] = useState<Option<User>>(null);
  const [tags, setTags] = useState<Tag[]>([]);
  const [tokenExpired, setTokenExpired] = useState(false);

  useEffect(() => {
    console.log("fetch initial");
    api
      .getIsLoggedIn()
      .then((resp) => {
        console.log("isLoggedIn", resp);
        if (typeof resp.data === "boolean" && resp.data) {
          let query = window.location.search;
          window.history.pushState("", "", `/find${query}`);
        } else {
          window.location.href = "/login";
        }

        api.getUserInfo().then((resp) => {
          const user = resp.data as User;
          setUser(user);
          if (user.scopeApiKey) {
            api
              .getScopeTags()
              .then((r) => {
                console.log(r);
                if (r.data) {
                  setTags(r.data as Tag[]);
                }
              })
              .catch((e) => {
                console.error(e);
                if (
                  "response" in e &&
                  "status" in e.response &&
                  e.response.status === 403
                ) {
                  // handle api token expired
                  setTokenExpired(true);
                  api.setScopeApiKey("");
                }
              });
          }
        });
      })
      .catch((e) => {
        console.error("Error during getIsLoggedIn", e);
        window.location.href = "/login";
      });
  }, []);

  return (
    <div className="App scroll-smooth h-full antialiased">
      <div className="h-full">
        {user && (
          <>
            <FindLookalikesView
              socket={socket}
              user={user}
              tags={tags}
              tokenExpired={tokenExpired}
              setShouldReceiveEmails={(isEnabled: boolean) => {
                api
                  .setShouldReceiveEmails(isEnabled)
                  .then((resp) => {
                    setUser(resp.data as User);
                  })
                  .catch((e) => {
                    console.error("could not set setShouldReceiveEmails:", e);
                  });
              }}
            />
          </>
        )}
      </div>
      <Toaster
        position="bottom-center"
        toastOptions={{
          style: {
            background: "#333",
            color: "#fff",
          },
        }}
      />
    </div>
  );
}

export default App;
